@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Typography */
  .hero {
    @apply text-4xl font-medium uppercase tracking-wider lg:text-7xl;
  }

  .homepage-hero {
    @apply text-3xl font-medium uppercase tracking-wider lg:text-6xl;
  }

  .page-heading {
    @apply text-4xl/[2.375rem] font-bold tracking-tighter lg:text-7xl;
  }

  .callout {
    @apply text-3xl font-medium uppercase tracking-wider lg:text-6xl;
  }

  .header-mid {
    @apply text-3xl font-bold tracking-tighter lg:text-5xl;
  }

  .header-small {
    @apply text-xl font-bold tracking-tighter;
  }

  .header-xsmall {
    @apply text-lg font-medium tracking-tight lg:text-2xl;
  }

  .body {
    @apply text-md tracking-tighter;
  }

  .body-bold {
    @apply body font-bold;
  }

  .link {
    @apply outline-blue-hover cursor-pointer rounded-md underline underline-offset-4 outline-offset-4 transition-all duration-120 ease-in-out hover:underline-offset-8 focus-visible:outline focus-visible:outline-2;
  }

  .body-link {
    @apply body link;
  }

  .body-small {
    @apply text-base tracking-tighter lg:text-base/[1.5rem];
  }

  .body-small-medium {
    @apply text-base font-medium tracking-tighter;
  }

  .body-small-bold {
    @apply body-small font-bold;
  }

  .body-small-link {
    @apply body-small link;
  }

  .tiny-text {
    @apply text-sm tracking-tighter;
  }

  .body-tiny {
    @apply tiny-text;
  }

  .tiny-text-link {
    @apply tiny-text link;
  }

  .caption-regular {
    @apply text-xxs/5 font-normal uppercase tracking-medium lg:text-sm;
  }

  .tag-regular {
    @apply text-xxs font-normal uppercase tracking-medium lg:text-xs;
  }

  .caption {
    @apply text-xs font-semibold uppercase tracking-wide lg:text-sm;
  }

  .caption-tiny-bold {
    @apply text-xs font-bold uppercase tracking-wider lg:text-xxs;
  }

  /* Layout */
  .main-grid {
    @apply container grid grid-cols-4 gap-x-4 md:grid-cols-12;
  }

  .lg-container {
    @apply mx-auto px-4 lg:px-8 xl:max-w-search-xl 2xl:max-w-search-2xl 2xl:px-24;
  }

  /* Z-index / layering */
  .z-header {
    @apply z-30;
  }

  .z-overlay {
    @apply z-40;
  }

  .z-modal {
    @apply z-50;
  }

  /* Focus outline */
  .focus-outline {
    @apply focus-visible:outline-blue-hover focus-visible:outline focus-visible:outline-2;
  }

  /* Misc */
  .hide-inner-buttons-when-hidden[aria-hidden='true'] button {
    @apply invisible;
  }

  /* Scrollbar */
  /**
   * Applying this class will hide the scrollbar from appearing
   * Make sure that the scrollable container is still accessible and scrollable in some way if hidden
   */
  .hide-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .hide-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .scrollbar-gutter-stable {
    scrollbar-gutter: stable;
  }
}
